export default {
  $vuetify: {
    back: 'Back',
    backTop: 'Back to the top',
    home: 'Home',
    detail: 'More Detail',
    noData: 'No data at present',
    noPdf: 'There is no specification at present.',
    noAttachment: 'There is no attachment at present.',
    downloadAttachment: 'Attachments Download',
    publishTime: 'Published at : ',
    updatedTime: 'Updated at : ',
    views: 'Page view : ',
    follow: 'Follow us',
    address: 'Room 408, Songshan Lake Industrial Building, No. 5 Industrial North Fourth Road, Songshan Lake Park, Dongguan City, Guangdong Province',
    product: {
      name: 'Products',
      title: 'Products',
      seriesMore: 'More series products',
      more: 'More Product Series',
      pdf: 'Specification',
      article: 'Related Article',
      attachment: 'Attachment Files'
    },
    case: {
      title: 'Application Cases',
      more: 'More Cases',
    },
    support: {
      title: 'Supports',
      more: 'More Content',
      watch: 'To Watch'
    },
    news: {
      title: 'News',
      more: 'More News'
    },
    about: {
      title: 'About Us',
      more: 'More Detail'
    },
    friend: {
      title: 'Friendly Link',
    },
    search: {
      none: 'No query result is available',
      result: 'Search Result',
      history: 'Recent Search History',
      lookingFor: 'What Are You LookingFor ?',
    },

  },
}